import "../assets/styles/pages/404.scss"

import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout subPage>
      <Seo title="404: Not found" />
    </Layout>
  )
}

export default NotFoundPage
